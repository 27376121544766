<template>
	<div data-component="deal-list">
		<h1>Deals</h1>
		<deal-list-item
			v-for="(deal, key) in getDeals"
			:key="key"
			:index="key"
			:deal="deal"
			:api="api"
			:isInPlaylist="true"
		/>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
		/>
		<portal
			to="fullscreenModal"
			v-if="simulatorIsVisible"
		>
			<simulator
				:mode="getSimulatorMode"
				:gameIds="getGameIds"
				:gameStates="getGameStates"
				:playersPositions="getPlayersPositions"
				:bidExplanationsList="getBidExplanationsList"
				:bidHintsList="getBidHintsList"
				:declarerHintsList="getDeclarerHintsList"
				:openingLeadsList="getOpeningLeadsList"
				:claimParametersList="getClaimParametersList"
				@close="closeSimulator"
			/>
		</portal>
	</div>
</template>

<script>

	import DealListItem from '@/components/activities/edit/activityTypes/deal/DealListItem';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';
	import { SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			DealListItem,
			Actions,
			Simulator: () => import('@/components/simulator/Simulator')
		},
		mixins: [actionClick],
		props: {
			api: {
				type: String,
				default: 'dealLibrary'
			}
		},
		data: () => ({
			simulatorIsVisible: false
		}),
		computed: {
			getSimulatorMode () {
				return SIMULATOR_MODES.PREVIEW;
			},
			getDeals () {
				return this.$store.getters['dealLibrary/getDealsInActivity'];
			},
			getGameIds () {
				return this.getDeals.map(deal => deal.id);
			},
			getPlayersPositions () {
				return this.getDeals.map(deal => deal.playerPosition);
			},
			getBidExplanationsList () {
				return this.getDeals.map(deal => deal.bidExplanations);
			},
			getBidHintsList () {
				return this.getDeals.map(deal => deal.bidHints);
			},
			getDeclarerHintsList () {
				return this.getDeals.map(deal => deal.declarerHint);
			},
			getOpeningLeadsList () {
				return this.getDeals.map(deal => deal.openingLead);
			},
			getClaimParametersList () {
				return this.getDeals.map(deal => deal.claimParameters);
			},
			getGameStates () {
				return this.$store.getters['dealLibrary/getGameStatesInActivity'];
			},
			getNumberOfDealsInActivity () {
				return this.$store.getters['dealLibrary/getNumberOfDealsInActivity'];
			},
			getActions () {
				return {
					primary: [
						(this.getNumberOfDealsInActivity ?
							{
								text: 'Preview',
								actionId: 'openSimulator'
							} : false
						)
					].filter(Boolean),
					secondary: [
						(this.getNumberOfDealsInActivity ?
							{
								text: 'Clear list',
								actionId: 'clearList'
							} : false
						)
					].filter(Boolean)
				};
			}
		},
		methods: {
			preview () {
				this.simulatorIsVisible = true;
			},
			getDealIsInActivityById (id) {
				return this.$store.getters['dealLibrary/getDealIsInActivityById']({
					id
				});
			},
			clearList () {
				this.$store.commit('dealLibrary/clearActivity');
			},
			closeSimulator () {
				this.simulatorIsVisible = false;
			},
			async openSimulator () {
				const startWithEmptyAuction = this.getDeals
					.filter(deal => deal.deal.startWithEmptyAuction)
					.map(deal => deal.id);
				const gameMode = this.$store.getters['gameEngine/getGameMode']({ legacyMode: this.getSimulatorMode });
				const roomToken = await this.$store.dispatch('gameEngine/getRoomToken', {
					mode: gameMode,
					dealIds: this.getGameIds,
					exitRedirectUrl: window.location.href,
					dealRecordingEnabled: [],
					startWithEmptyAuction
				});
				await this.$store.dispatch('gameEngine/openGameEngine', { roomToken });
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-list'] {
		width:100%;
		padding:rem(24) rem(24) rem(12);
		border:1px solid $c-brand-grey-lighter-2;
		h1 {
			margin-bottom:rem(12);
			font-size:rem(12);
			font-weight:700;
		}
	}

</style>

<template>
	<div data-component="video">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="formLoaded"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="activity.title"
			/>
			<upload
				fileType="mp4"
				:form.sync="form"
				:media="activity.media"
				@deleteMedia="deleteMedia"
				:isWaiting="isWaiting"
			/>
			<V2Actions
				:actions="getActions"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField        from '@/components/forms/InputField';
	import Upload            from '@/components/uploads/Upload';
	import V2Actions            from '@/components/v2/ui/V2Actions';
	import activityDefaults  from '@/mixins/activityDefaults';

	export default {
		components: {
			InputField,
			Upload,
			V2Actions
		},
		mixins: [activityDefaults],
		data: () => ({
			form: undefined
		}),
		computed: {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='quiz'] {}

</style>
